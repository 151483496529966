import { Container } from "./styles";
import facepalm from '../../../assets/facepalm.svg'
import thumbsup from '../../../assets/thumbsup.svg'

export function Error() {
    return (
        <Container>
            <div>
                <p>Oops! This wasn't supposed to happen...</p>
                <img alt="Facepalm" src={facepalm}/>
            </div>
            <div>
                <p>...please refresh the page and try again</p>
                <img alt="Facepalm" src={thumbsup}/>
            </div>
        </Container>
    );
}
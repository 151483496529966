import { ReactElement, useEffect, useRef, useState } from "react"
import { Container } from "./styles"

type SmoothHeightChangeProps = {
    children: ReactElement
}

export const SmoothHeightChange = ({children}:  SmoothHeightChangeProps) => {

    const [currentChildren, setCurrentChildren] = useState<ReactElement>(children);

    const containerRef = useRef<HTMLDivElement>({} as HTMLDivElement);
    
    useEffect(() => {
        let firstTimeout: NodeJS.Timeout;
        let secondTimeout: NodeJS.Timeout; 
        if (children.key !== currentChildren.key) {

            containerRef.current.style.maxHeight = "150px";
            containerRef.current.style.overflow = "hidden";
            firstTimeout = setTimeout(() => {
                secondTimeout = setTimeout(() => {
                    setCurrentChildren(children);
                    containerRef.current.style.maxHeight = "5000px";
                    //containerRef.current.style.overflow = "initial";
                }, 500);
            }, 50);
        }

        return () => {
            if (firstTimeout) {
                clearTimeout(firstTimeout)
            }
            if (secondTimeout) {
                clearTimeout(secondTimeout)
            }
        }
    }, [children, currentChildren]);


    return (
        <Container ref={containerRef}>
                {currentChildren}
        </Container>
    )
}
import { FixedContainer, Content } from "./styles";
import { ReactComponent as MessageIcon } from './../../assets/message-icon.svg';
import { ReactComponent as CloseIcon } from './../../assets/close_icon.svg';
import { Rotate } from "../Animation/Rotate";
import { FadeIn } from "../Animation/FadeIn";
import { useWidgetSettings } from "../../contexts/WidgetSettingsContext";
import { Wriggle } from "../Animation/Wriggle";
import { ScreenSizes } from "../../styles/global";
import { useState } from "react";

type ContactButtonProps = {
    isOpen: boolean;
    toggleChannels: () => void
}

const ContactButton = ({isOpen, toggleChannels}: ContactButtonProps) => {
    const {widgetSettings} = useWidgetSettings();
    const buttonTitle = !!widgetSettings?.buttonTitle;
    const buttonImageUrl = widgetSettings?.buttonImageUrl;

    const [showButtonImage, setShowButtonImage] = useState<boolean>(true);

    return (
            <Rotate is90Deg={!buttonTitle} isClockwiseRotation={isOpen}>
                <FixedContainer
                    onClick={toggleChannels}
                    style={{
                        marginBottom: ScreenSizes.widgetButton.marginBottom(widgetSettings?.customStyles?.extraMarginBottom),
                        marginRight: ScreenSizes.widgetButton.marginRight(widgetSettings?.customStyles?.extraMarginRight),
                    }}
                    >
                    <FadeIn render={true}>
                        <Wriggle running={!isOpen}>
                            <Content
                                {...widgetSettings?.customStyles}
                                style={{
                                    padding: showButtonImage && buttonImageUrl ? 0 : "15px",
                                }}
                            >
                                {showButtonImage && buttonImageUrl ? (
                                    <img
                                        src={buttonImageUrl}
                                        loading="eager"
                                        style={{
                                            maxHeight: "50px",
                                            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)"
                                        }}
                                        alt={buttonTitle ? widgetSettings.buttonTitle : "Message us"}
                                        onError={() => {
                                            setShowButtonImage(false);
                                        }}
                                    />
                                ) : (
                                    <>
                                        <FadeIn render={!isOpen} style={{
                                            display: !isOpen ? "" : "none",
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}>
                                            <MessageIcon style={{width: buttonTitle ? "25px" : "30px", height: "auto"}} />
                                            {buttonTitle && <span style={{
                                                    color: widgetSettings?.customStyles?.secondaryColour,
                                                    marginLeft: "5px"
                                                }}>{widgetSettings.buttonTitle}</span>}
                                        </FadeIn>
                                        <FadeIn render={isOpen} style={{
                                            display: isOpen ? "" : "none",
                                            flexDirection: "row-reverse",
                                            alignItems: "center"
                                        }}>
                                            <span style={{
                                                width: buttonTitle ? 'initial' : "30px",
                                                height: buttonTitle ? '25px' : "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignContent: "center"}}>
                                                <CloseIcon style={{width: buttonTitle ? "25px" : "18px", height: "auto"}} />
                                            </span>
                                            {buttonTitle && <span style={{
                                                    color: widgetSettings?.customStyles?.secondaryColour,
                                                    marginRight: "5px",
                                                    transform: "rotate(180deg)"
                                                }}>{widgetSettings.buttonTitle}</span>}
                                        </FadeIn>
                                    </>
                                )}
                            </Content>
                        </Wriggle>
                    </FadeIn>
                </FixedContainer>
            </Rotate>
    )
}

export default ContactButton;
import { ChannelIcon } from "./channelIcon";
import { Container } from "./styles";

export type ChannelItemProp = {
    icon: string;
    label: string;
    onClick?: () => void
}

export function ChannelItem({icon, label, onClick}: ChannelItemProp) {
    return (
        <Container onClick={onClick}>
            <div><ChannelIcon src={icon} alt={label}/></div>
            <div>{label}</div>
        </Container>
    )
}


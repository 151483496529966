import styled from "styled-components";
import { ScreenSizes } from "../../../styles/global";

export const Container = styled.div`

    .widget-title {
        font-size: 1em;
        font-weight: 700;
        line-height: 1.1875em;
        display: inline-block;
        margin-bottom: 15px !important;
        @media (max-height:  ${ScreenSizes.fullscreenMaxHeight}) and (orientation: landscape) {
            text-align: center;
        }
    }

    & > div {
        display: grid;
        grid-column-gap: 22px;
        grid-row-gap: 14px;
        @media (max-height:  ${ScreenSizes.fullscreenMaxHeight}) and (orientation: landscape) {
            grid-template-columns: repeat(2, 1fr);
            
        }
    }
`
import styled from "styled-components";
import { WidgetCustomStyles } from "../../services/channels";

export const FixedContainer = styled.div<WidgetCustomStyles>`
  z-index: 9999998;

  position: fixed;
  right: 0;
  bottom: 0;

  cursor: pointer;
`

export const Content = styled.div<WidgetCustomStyles>`
  border-radius: 12px;

  background-color: ${props => props.primaryColour || '#0A1546'};
  color: ${props => props.secondaryColour || '#ffffff'};
  padding: 20px;

  svg {
    fill: ${props => props.secondaryColour || '#ffffff'};
  }
`
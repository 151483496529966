import { DialogType, useModalDialogContext } from "../../contexts/ModalDialogContext";
import { ChannelsList } from "./ChannelsList";
import { ContactForm } from "./ContactForm";
import { BottomContainer, CloseButton, FixedContainer, Content, Footer, TopContainer } from "./styles";
import {ReactComponent as CloseButtonIcon} from '../../assets/close_icon.svg';
import { Error } from "./Error";
import { Logo } from "./Logo";
import { SmoothHeightChange } from "../Animation/SmoothHeightChange";
import { useWidgetSettings } from "../../contexts/WidgetSettingsContext";
import { FadeIn } from "../Animation/FadeIn";
import { ScreenSizes } from "../../styles/global";
import { LocationList } from "./LocationList";
import { useAnalytics } from "../../hooks/Analytics";
import React, { useEffect, useState } from "react";
import { ConfirmationDialog } from "./ConfirmationDialog";

type ChannelsProps = {
    closeChannels: () => void;
    showChannels: boolean;
}

const Channels = ({closeChannels, showChannels}: ChannelsProps) => {
    const {isOpen: isModalDialogOpen, dialogType, confirmationText, confirmationAction} = useModalDialogContext();
    const {widgetSettings, errorOnLoading, widgetId} = useWidgetSettings();
    const send_analytics = useAnalytics();

    const [modalDialog, setModalDialog] = useState<React.ReactElement | undefined>(undefined);

    useEffect(() => {
      if (!isModalDialogOpen) {
        setModalDialog(undefined);

        return;
      }

      if (dialogType === DialogType.CONTACT_FORM) {
        setModalDialog(<ContactForm key="contactForm" />);

        return;
      }

      if (
        dialogType === DialogType.CONFIRMATION &&
        confirmationText &&
        confirmationAction
      ) {
        setModalDialog(<ConfirmationDialog text={confirmationText} action={confirmationAction} />);

        return;
      }
    }, [isModalDialogOpen, dialogType, confirmationText, confirmationAction]);

    return (
      <FixedContainer {...widgetSettings?.customStyles} marginBottom={ScreenSizes.channels.marginBottom(widgetSettings?.customStyles?.extraMarginBottom)} marginRight={ScreenSizes.channels.marginRight(widgetSettings?.customStyles?.extraMarginRight)}>
        <FadeIn render={showChannels}>
          <TopContainer {...widgetSettings?.customStyles}>
            <CloseButton
              onClick={closeChannels}
              {...widgetSettings}
            >
              <CloseButtonIcon />
            </CloseButton>

            <Logo src={widgetSettings?.logo} />
          </TopContainer>
          <BottomContainer {...widgetSettings?.customStyles}>
            <Content>
              {errorOnLoading && <Error />}
              {!errorOnLoading && widgetSettings && (
                <SmoothHeightChange>
                  {modalDialog || (widgetId ? (
                    <ChannelsList
                      channels={widgetSettings.locationSettings.find(location => location.widgetId === widgetId)!.channelByType}
                      externalContactFormUrl={widgetSettings.locationSettings.find(location => location.widgetId === widgetId)!.externalContactFormUrl}
                      key="channelsList"
                    />
                  ) : (
                    <LocationList key="locationList" />
                  ))}
                </SmoothHeightChange>
              )}
            </Content>
            <Footer>
              {!widgetSettings?.hidePoweredBy &&
              <>
                Powered by{" "}
                <a
                  href="https://getfuzey.com"
                  onClick={() => send_analytics("powered_by_fuzey_opened")}
                  rel="noreferrer"
                  target="_blank"
                >
                  Fuzey
                </a>
              </>
              }
            </Footer>
          </BottomContainer>
        </FadeIn>
      </FixedContainer>
    );
}

export default Channels;
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    div {
        display: flex;
        align-items: center;
        p {
            font-size: 1.5em;
        }

        img {
            height: 100%;
        }
    }
`
import { useWidgetSettings } from "../../../contexts/WidgetSettingsContext";
import { ChannelDomain, ChannelTypes } from "../../../services/channels";
import { ContactForm } from "../ChannelItem/contactForm";
import { Email } from "../ChannelItem/email";
import { Instagram } from "../ChannelItem/instagram";
import { Messenger } from "../ChannelItem/messenger";
import { Sms } from "../ChannelItem/sms";
import { Whatsapp } from "../ChannelItem/whatsapp";
import { Container } from "./styles";

type ChannelsListProps = {
    channels: Map<ChannelTypes, ChannelDomain>
    externalContactFormUrl?: string;
}

export function ChannelsList({channels, externalContactFormUrl}: ChannelsListProps) {
    const {widgetSettings} = useWidgetSettings()

    return (
        <Container>
            <span className="widget-title">Message us on...</span>
            <div>
                <Whatsapp channel={channels.get("whatsapp")} />
                <Sms channel={channels.get("sms")} />
                <Messenger channel={channels.get("messenger")} />
                <Instagram channel={channels.get("instagram")} />
                <Email channel={channels.get("email")} />
                {widgetSettings?.hideContactForm ? null : (
                    <ContactForm externalContactFormUrl={externalContactFormUrl}/>
                )}
            </div>
        </Container>
    )
}
import { useModalDialogContext } from "../../../contexts/ModalDialogContext";
import { PrimaryButton, SecondaryButton } from "./styles";

interface ConfirmationDialogProps {
    text: React.ReactNode;
    action: () => void;
}

export function ConfirmationDialog({ text, action }: ConfirmationDialogProps) {
    const { close } = useModalDialogContext();
    return (
        <div>
            {text}
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem"
            }}>
                <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
                <PrimaryButton onClick={() => action()}>Proceed</PrimaryButton>
            </div>
        </div>
    )
}
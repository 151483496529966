import { useWidgetSettings } from "../../../contexts/WidgetSettingsContext";
import { Container } from "./styles";

type LogoProps = {
    src?: string;
}

export function Logo({src}: LogoProps) {
    const {widgetSettings} = useWidgetSettings();

    return (
        <Container
            {...widgetSettings?.customStyles} 
        >
            {src ? <img alt="Merchant Logo" src={src} /> : <span className="widget-title">Contact us now!</span>}
        </Container>
    )
}
import { ReactNode, useEffect, useRef } from "react"
import { Container } from "./styles"

type FadeInProps = {
    isClockwiseRotation: boolean
    is90Deg?: boolean
    children: ReactNode
}

export function Rotate({children, isClockwiseRotation, is90Deg}: FadeInProps) {
    const component = useRef<HTMLDivElement>({} as HTMLDivElement)

    useEffect(() => {
        if (isClockwiseRotation) {
            const componentToRotate = component.current.firstChild as HTMLElement;
            componentToRotate.style.transform = is90Deg ? "rotate(90deg)" : "rotate(180deg)"
        } else {
            const componentToRotate = component.current.firstChild as HTMLElement;
            componentToRotate.style.transform = "rotate(0deg)"
        }
    }, [isClockwiseRotation, is90Deg])

    return (
        <Container ref={component}>
            {children}
        </Container>
    )
}
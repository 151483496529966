type ExpirableItem = {
    content: any,
    expiration: number
}

export default class LocalStorageService {
    public static setWithExpiration<T>(key: string, content: T) {
        const AN_HOUR = (60 * 60 * 1000);
        const expirableItem = {
            content: encode(content),
            expiration: Date.now() + AN_HOUR
        }
        localStorage.setItem(key, JSON.stringify(expirableItem));
    }

    public static getWithExpiration<T>(key: string): T | undefined {
        const plainTextItem = localStorage.getItem(key)
        if (plainTextItem) {
            const item = JSON.parse(plainTextItem) as ExpirableItem;
            if (item.expiration > Date.now()) {
                return decode(item.content) 
            }
        }
        return undefined
    }
}

function encode<T>(content: T) {
    const encodedContent = window.btoa(encodeURIComponent(JSON.stringify(content)));
    return encodeURIComponent((process.env.REACT_APP_ENCODE_SALT || 'default_salt') + encodedContent);
}

function decode<T>(content: string): T {
    const encodedWithoutSalt = decodeURIComponent(content).replace((process.env.REACT_APP_ENCODE_SALT || 'default_salt'), '')
    return JSON.parse(decodeURIComponent(window.atob(encodedWithoutSalt))) as T;
}
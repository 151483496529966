import { ReactNode, useEffect, useRef, useState } from "react"
import { isMobile } from "../../Channels/ChannelItem/sms";
import { Container } from "./styles"

type FadeInProps = {
    render: boolean;
    size: "sm" | "md" | "lg";
    children: ReactNode;
}

function getScaleBySize(size: "sm" | "md" | "lg") {
    const isMobileDevice = isMobile();

    if (isMobileDevice) {
        return 1;
    }

    switch (size) {
        case "sm":
            return 0.8;
        case "md":
            return 1;
        case "lg":
            return 1.2;
        default:
            return 1;
    }
}

export function Scale({render, size, children}: FadeInProps) {
    const component = useRef<HTMLDivElement>({} as HTMLDivElement)
    const [unmount, setUnmount] = useState(false);

    useEffect(() => {
        let firstScaleTimeout: NodeJS.Timeout; // exceed its final position to create an effect of going back to where it should be / also used to scale out
        let secondScaleTimeout: NodeJS.Timeout; // place the element where it should be
        let detroyComponentTimeout: NodeJS.Timeout; // timeout to detroy the component after animation to free memory
        if (render) {
            setUnmount(false)
            firstScaleTimeout = setTimeout(() => {
                const componentToScale = component.current.firstChild as HTMLElement;
                componentToScale.style.transform = `scale(${getScaleBySize(size) * 1.2})`;
                componentToScale.style.transformOrigin = "right bottom";
                componentToScale.style.overflow = "auto";

                secondScaleTimeout = setTimeout(() => {
                    const componentToScale = component.current.firstChild as HTMLElement;
                    componentToScale.style.transform = `scale(${getScaleBySize(size)})`;
                }, 600);
            }, 100);
        } else {
            firstScaleTimeout = setTimeout(() => {
                const componentToScale = component.current.firstChild as HTMLElement;
                componentToScale.style.transform = `scale(${getScaleBySize(size) * 0.5})`;
                componentToScale.style.transformOrigin = "right bottom";

                detroyComponentTimeout = setTimeout(() => {
                    componentToScale.style.overflow = "hidden";
                    setUnmount(true)
                }, 200);
            }, 100);
        }
        return () => {
            if (firstScaleTimeout) {
                clearTimeout(firstScaleTimeout)
            }
            if (secondScaleTimeout) {
                clearTimeout(secondScaleTimeout);
            }
            if (detroyComponentTimeout) {
                clearTimeout(detroyComponentTimeout);
            }
        };
    }, [render, size])

    if (unmount) {
        return <></>
    }
    return (
        <Container ref={component}>
            {children}
        </Container>
    )
}
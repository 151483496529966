
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    :root {
        // define global variables here
    } 
    
    .fuzey-widget { 
        font-size: medium;
        * {
            box-sizing: border-box;
        }

        *, input, textarea, button {
            box-sizing: border-box;
            font-family: 'Lato', Arial, sans-serif;
        }

        h1, h2, h3, h4 {
            margin: 0;  
        }
    }
`

export const ScreenSizes = {
    fullscreenMaxWidth: '429px',
    fullscreenMaxHeight: '429px',
    nonfullScreenMinWidth: '430px',
    nonfullScreenMinHeight: '430px',

    channels: {
        maxHeight: '630px',
        marginRight: (extraSpacing: number = 0) => `${16+extraSpacing}px`,
        marginBottom: (extraSpacing: number = 0) => `${96+extraSpacing}px` // 64 (button) + 16 (button margin-bottom) + 16 (margin between button and channels)
    },
    widgetButton: {
        height: '64px',
        width: '64px',
        marginRight: (extraSpacing: number = 0) => `${16+extraSpacing}px`,
        marginBottom: (extraSpacing: number = 0) => `${16+extraSpacing}px`
    },
    
}
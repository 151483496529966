import styled from "styled-components";

type ContainerProps = {
    minHeight?: string;
}

export const Container = styled.div<ContainerProps>`
    min-height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: ${props => props.minHeight || '350px'};
`
import styled, { css, keyframes } from "styled-components";

const wriggle = keyframes`
    0% {
        transform: rotate(-2deg);
    }
    2% {
        
        transform: rotate(2deg);
    }
    4% {
        
        transform: rotate(-2deg);
    }
    6% {
        transform: rotate(4deg);
    }
    8% {
        transform: rotate(-4deg);
    }
    10% {
        transform: rotate(4deg);
    }
    12% {
        transform: rotate(-6deg);
    }
    14% {
        transform: rotate(6deg);
    }
    16% {
        transform: rotate(-2deg);
    }
    18% {
        transform: rotate(2deg);
    }
    20% {
        transform: rotate(-2deg);
    }
    22% {
        transform: rotate(0deg);
    }
`

type ContainerProps = {
    running?: boolean
}

const animation = css`
        ${wriggle} 10s 5s infinite
    `

export const Container = styled.div<ContainerProps>`
    animation: ${props => props.running ? animation : ''};
`




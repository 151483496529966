import styled from "styled-components";

export const Container = styled.div`
    
    & > :first-child {
        transform: scale(0.5);
        transition: transform 1s;
        background-color: transparent;
        overflow: hidden;
    }
`

import { createContext, ReactNode, useCallback, useContext, useState } from "react";

export enum DialogType {
    CONTACT_FORM = "contact_form",
    CONFIRMATION = "confirmation",
}

interface OpenDialogOptions {
    confirmationText?: React.ReactElement;
    confirmationAction?: () => void;
}

type ModalDialog = {
    dialogType?: DialogType;
    confirmationText?: React.ReactElement;
    confirmationAction?: () => void;
    isOpen: boolean;
    open: (type: DialogType, options?: OpenDialogOptions) => void;
    close: () => void;
}

const ModalDialogContext = createContext({} as ModalDialog);

type ModalDialogContextProviderProps = {
    children: ReactNode
}

export function ModalDialogContextProvider({children}: ModalDialogContextProviderProps) {
    const [dialogType, setDialogType] = useState<DialogType | undefined>();
    const [confirmationText, setConfirmationText] = useState<React.ReactElement | undefined>(undefined);
    const [confirmationAction, setConfirmationAction] = useState<(() => void) | undefined>(undefined);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const open = useCallback((type: DialogType, options?: OpenDialogOptions) => {
        setDialogType(type);

        if (
            type === DialogType.CONFIRMATION &&
            options?.confirmationText &&
            options?.confirmationAction
        ) {
            setConfirmationText(options.confirmationText);
            setConfirmationAction(() => options.confirmationAction);
        } else {
            setConfirmationText(undefined);
            setConfirmationAction(undefined);
        }

        setIsOpen(true)
    }, []);

    const close = useCallback(() => {
        setIsOpen(false)
        setDialogType(undefined);
        setConfirmationText(undefined);
        setConfirmationAction(undefined);
    }, []);

    return (
        <ModalDialogContext.Provider
            value={{
                dialogType,
                confirmationText,
                isOpen,
                confirmationAction,
                open,
                close,
            }}>
            {children}
        </ModalDialogContext.Provider>
    )
}

export const useModalDialogContext = () => useContext(ModalDialogContext)
import styled from "styled-components";

export const Container = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 100%;
    position: relative;

    & div {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: 6px solid transparent;
    }

    & div:first-child {
      border-top-color: #000000;
      animation: half-circle-spinner-animation 1s infinite;
    }

    & div:last-child {
      border-bottom-color: #000000;
      animation: half-circle-spinner-animation 1s infinite alternate;
    }

    @keyframes half-circle-spinner-animation {
      0% {
        transform: rotate(0deg);

      }
      100%{
        transform: rotate(360deg);
      }
    }
`;
import { ChannelItem } from ".";
import { ChannelDomain } from "../../../services/channels";
import email from "../../../assets/email.svg";
import { useAnalytics } from "../../../hooks/Analytics";

type EmailProps = {
  channel?: ChannelDomain;
};

// change this component to render contact form once it supports email
export function Email({ channel }: EmailProps) {

  const send_analytics = useAnalytics();

  if (!channel || channel.type !== "email" || !channel.link) {
    return <></>;
  }
  return (
    <ChannelItem
      icon={email}
      label="Email"
      onClick={() => {
        send_analytics("channel_selected", channel.id, channel.type);
        window.open(`${channel.link}`, "_blank");
      }}
    />
  );
}

import { Spinner } from "../../Spinner";
import { Container } from "./styles";

type SpinnerContainerProps = {
    minHeight?: string;
}

export function SpinnerContainer({minHeight}: SpinnerContainerProps) {
    return (
        <Container id="iframe-spinner" minHeight={minHeight}>
            <Spinner />
        </Container>
    )
}
import { ReactNode, useEffect, useRef } from "react";
import { Container } from "./styles";

type FadeInProps = {
    render: boolean
    children: ReactNode
    style?: React.CSSProperties
}

export function FadeIn({render, children, style}: FadeInProps) {

    const component = useRef<HTMLDivElement>({} as HTMLDivElement)

    useEffect(() => {
        let timeoutOpacity: NodeJS.Timeout;
        let timeoutDisplayNone: NodeJS.Timeout;
        if (render) {
            component.current.style.display = "flex";
            timeoutOpacity = setTimeout(() => {
                component.current.style.opacity = "1";
            }, 100);
        } else {
            timeoutOpacity = setTimeout(() => {
                component.current.style.opacity = "0";
                timeoutDisplayNone = setTimeout(() => {
                    component.current.style.display = "none";
                }, 300);
            }, 100);
        }

        return () => {
            if (timeoutOpacity) {
                clearTimeout(timeoutOpacity)
            }
            if (timeoutDisplayNone) {
                clearTimeout(timeoutDisplayNone)
            }
        };
    }, [render])

    return (
        <Container ref={component} style={style}>
            {children}
        </Container>
    );
}
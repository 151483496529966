import { ChannelDomain } from "../../../services/channels";
import instagram from '../../../assets/instagram.svg';
import { ChannelItem } from ".";
import { useAnalytics } from "../../../hooks/Analytics";

type InstagramProps = {
    channel?: ChannelDomain
}

export function Instagram({channel}: InstagramProps) {

    const send_analytics = useAnalytics();

    if (!channel || channel.type !== 'instagram') {
        return <></>;
    }
    return (
        <ChannelItem icon={instagram} label="Instagram" onClick={() => {
               send_analytics("channel_selected", channel.id, channel.type);
            window.open(`${channel.link}`, "_blank")
        }}/>
    )
}
import styled from "styled-components";
import { WidgetCustomStyles } from "../../../services/channels";
import { ScreenSizes } from "../../../styles/global";

export const Container = styled.div<WidgetCustomStyles>`
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 130px;
    height: 220px;
    
    .widget-title {
        text-align: center;
        font-size: 1.25em;
        font-weight: 700;
        line-height: 3em;
    }

    @media (max-height: ${ScreenSizes.fullscreenMaxHeight}) and (orientation: landscape) { 
        padding-top: 13px;
        padding-bottom: 68px;
        height: 131px;
    }

    img {
        height: 60px;
        width: auto;
        
        @media (max-height: ${ScreenSizes.fullscreenMaxHeight}) and (orientation: landscape) { 
            height: 50px;
        }
    }
`
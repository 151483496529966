import styled from "styled-components";
import { WidgetCustomStyles } from "../../../services/channels";

export const SecondaryButton = styled.button<WidgetCustomStyles>`
    border-radius: 5px;
    background-color: transparent;
    padding: 10px;
    margin: 4px 0;
    border: 1px solid ${props => props.secondaryColour || '#ddd'};
`

export const PrimaryButton = styled.button<WidgetCustomStyles>`
    border-radius: 5px;
    background-color: transparent;
    padding: 10px;
    margin: 4px 0;
    border: 1px solid ${props => props.primaryColour || '#000'};
`

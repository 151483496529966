import { ReactNode } from "react";
import { Container } from "./styles";

type WriggleProps = {
    children: ReactNode;
    running?: boolean
}

export function Wriggle({children, running}: WriggleProps) {
    return (
        <Container running={running}>
            {children}
        </Container>
    )
}
import { createContext, ReactNode, useContext, useState } from "react";
import WidgetService, { ChannelDomain, ChannelTypes, WidgetCustomStyles } from "../services/channels";

export type WidgetLocationSettings = {
    widgetId: string;
    channelByType: Map<ChannelTypes, ChannelDomain>;
    externalContactFormUrl?: string;
    locationName: string;
    merchantPublicId: string;
    merchantCountry?: string;
}

export type WidgetSettings = {
    logo: string;
    locationSettings: WidgetLocationSettings[];
    customStyles?: WidgetCustomStyles;
    hidePoweredBy?: boolean;
    preventAutoOpen?: boolean;
    delayAutoOpen?: number;
    buttonTitle?: string;
    successTitle?: string;
    hideContactForm?: boolean;
    successSubtitle?: string;
    size?: "sm" | "md" | "lg";
    buttonImageUrl?: string;
}

export const getLocationName = (locationSettings: WidgetLocationSettings): string => {
    const lastPart = locationSettings.locationName.split("/").shift()!.trim();

    return lastPart;
}

export const getPreviousFolder = (locationSettings: WidgetLocationSettings[], depth: number, folder: string | null): string | null => {
    const foldersOnPreviousDepth = getLocationsInDepth(locationSettings, depth - 1)
        .filter(location => {
            const folders = location.locationName.split("/").map(n => n.trim());

            if (!folder) {
                return true;
            }

            return folders.includes(folder);
        })
        .map(location => {
            const folders = location.locationName.split("/").map(n => n.trim());

            return folders[depth - 2];
        })
        .filter((folder) => !!folder);

    if (foldersOnPreviousDepth.length === 0) {
        return null;
    }

    return foldersOnPreviousDepth[0];
}

export const getNestedLocations = (
    locationSettings: WidgetLocationSettings[],
    currentDepth: number,
    currentFolder: string | null,
): WidgetLocationSettings[] => {
    return getLocationsInDepth(locationSettings, currentDepth)
        .filter(location => {
            const folders = location.locationName.split("/").map(n => n.trim());

            if (!currentFolder) {
                return true;
            }

            return folders.includes(currentFolder) && folders[currentDepth - 1] === currentFolder;
        })
        .map(location => {
            const folders = location.locationName.split("/").map(n => n.trim());

            return {
                ...location,
                locationName: folders.slice(currentDepth).join(" / ")
            }
        })
        .filter((location, index, allLocations) => {
            const name = getLocationName(location);
            return allLocations.slice(index).filter(l => getLocationName(l) === name).length === 1;
        }) as WidgetLocationSettings[];
}

export const getLocationsInDepth = (
    locationSettings: WidgetLocationSettings[],
    currentFolder: number
): WidgetLocationSettings[] => {
    if (currentFolder === 0) {
        return locationSettings;
    }

    return locationSettings.filter(location => {
        const folders = location.locationName.split("/").map(n => n.trim());
        return folders.length > currentFolder;
    });
}

type WidgetSettingsContextProps = {
    widgetId?: string;
    isLoaded?: boolean;
    errorOnLoading?: boolean
    widgetSettings?: WidgetSettings;
    loadSettings: (widgetIds: string[]) => void;
    setWidgetId: (widgetId: string) => void;
}

const WidgetSettingsContext = createContext({} as WidgetSettingsContextProps)

type WidgetSettingsContextProviderProps = {
    children: ReactNode
}

export const WidgetSettingsContextProvider = ({children}: WidgetSettingsContextProviderProps) => {
    const [settings, setSettings] = useState<WidgetSettings | undefined>(undefined);
    const [widgetId, setWidgetId] = useState("");
    const [errorOnLoading, setErrorOnLoading] = useState(false);

    const loadSettings = async (widgetIds: string[]) => {
        if (!settings) {
            let sett = {locationSettings: [] as WidgetLocationSettings[]} as WidgetSettings

            const widgets = await WidgetService.getAllByIds(widgetIds);

            if (widgets.length !== widgetIds.length) {
                setErrorOnLoading(true);
            }

            widgetIds.forEach((widgetId) => {
                const widget = widgets.find(w => w.widgetId === widgetId);

                if (!widget) {
                    return;
                }

                const channelByType = widget?.channels.reduce((channels, currentChannel) => {
                    channels.set(currentChannel.type, currentChannel);
                    return channels;
                }, new Map<ChannelTypes, ChannelDomain>())

                sett = {
                    logo: widget.logo,
                    locationSettings: [...sett.locationSettings, {
                        merchantPublicId: widget.merchantPublicId,
                        merchantCountry: widget.merchantCountry,
                        widgetId,
                        locationName: widget.locationName,
                        channelByType, externalContactFormUrl: widget.externalContactFormUrl
                    }],
                    customStyles: widget.customStyles,
                    size: widget.size,
                    hidePoweredBy: widget.hidePoweredBy,
                    preventAutoOpen: widget.preventAutoOpen,
                    delayAutoOpen: widget.delayAutoOpen,
                    buttonTitle: widget.buttonTitle,
                    successTitle: widget.successTitle,
                    successSubtitle: widget.successSubtitle,
                    hideContactForm: widget.hideContactForm,
                    buttonImageUrl: widget.buttonImageUrl
                };
            });
            setSettings(sett);
            if (sett.locationSettings.length === 1) {
                setWidgetId(sett.locationSettings[0].widgetId);
            }
        }
    }

    return (
        <WidgetSettingsContext.Provider value={{
            widgetId: widgetId,
            widgetSettings: settings,
            isLoaded: !!settings,
            errorOnLoading,
            loadSettings,
            setWidgetId
            }}>
            {children}
        </WidgetSettingsContext.Provider>
    )
}

export const useWidgetSettings = () => useContext(WidgetSettingsContext);


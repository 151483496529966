import { useEffect, useState } from "react";
import { getLocationName, getNestedLocations, getPreviousFolder, useWidgetSettings, WidgetLocationSettings } from "../../../contexts/WidgetSettingsContext";
import { Container, MerchantLocation } from "./style";

export function LocationList() {
    const {widgetSettings, setWidgetId} = useWidgetSettings()
    const [localLocations, setLocalLocations] = useState<WidgetLocationSettings[]>(JSON.parse(JSON.stringify(widgetSettings?.locationSettings)));
    const [currentDepth, setCurrentDepth] = useState<number>(0);
    const [currentFolder, setCurrentFolder] = useState<string | null>(null);

    useEffect(() => {
        setLocalLocations(getNestedLocations(widgetSettings!.locationSettings, currentDepth, currentFolder));
    }, [currentFolder, currentDepth, widgetSettings]);

    return (
        <Container>
            <span className="widget-title">Message us on...</span>
            {currentDepth >= 1 ? (
                <button
                    style={{
                        width: "fit-content",
                        borderRadius: "12px",
                        border: `1px solid ${widgetSettings?.customStyles?.secondaryColour || "lightgrey"}`,
                        backgroundColor: "transparent",
                        color: widgetSettings?.customStyles?.secondaryColour || "lightgrey",
                        marginBottom: "8px",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        const previousFolder = getPreviousFolder(
                            widgetSettings!.locationSettings,
                            currentDepth,
                            currentFolder
                        );
                        const previousDepth = currentDepth - 1;

                        setCurrentFolder(previousFolder);
                        setCurrentDepth(previousDepth);
                    }}
                >Back</button>
            ) : null}
            {localLocations.map((location) => {
                return (
                    <MerchantLocation
                        {...widgetSettings?.customStyles}
                        onClick={() => {
                            const nextDepth = currentDepth + 1;
                            const nextFolder = getLocationName(location);
                            const nextLocations = getNestedLocations(
                                widgetSettings!.locationSettings,
                                nextDepth,
                                nextFolder
                            );

                            if (nextLocations.length === 0) {
                                setWidgetId(location.widgetId);
                            } else {
                                setCurrentFolder(nextFolder);
                                setCurrentDepth(nextDepth);
                            }
                        }}
                    >
                        {getLocationName(location)}
                    </MerchantLocation>
                )
            })}
        </Container>
    )
}

import { ChannelItem } from ".";
import { ChannelDomain } from "../../../services/channels";
import whatsapp from "../../../assets/whatsapp.svg";
import { useAnalytics } from "../../../hooks/Analytics";

type WhatsappProps = {
  channel?: ChannelDomain;
};

export function Whatsapp({ channel }: WhatsappProps) {

  const send_analytics = useAnalytics();

  if (!channel || channel.type !== "whatsapp") {
    return <></>;
  }
  return (
    <ChannelItem
      icon={whatsapp}
      label="WhatsApp"
      onClick={() => {
        send_analytics("channel_selected", channel.id, channel.type);
        window.open(`${channel.link}`, "_blank");
      }}
    />
  );
}

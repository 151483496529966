import styled from "styled-components";
import { ScreenSizes } from "../../../styles/global";

export const Container = styled.div`

`

export const IFrame = styled.iframe`
    min-height: 350px; 
    border: 0px;
    display: none;

    @media (max-height: ${ScreenSizes.fullscreenMaxHeight}) and (orientation: landscape) {
        min-height: 300px;
    }
`
import styled from "styled-components";
import { WidgetCustomStyles } from "../../../services/channels";
import { ScreenSizes } from "../../../styles/global";

export const Container = styled.div`
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .widget-title {
        font-size: 1em;
        font-weight: 700;
        line-height: 1.1875em;
        display: inline-block;
        margin-bottom: 15px !important;
        @media (max-height:  ${ScreenSizes.fullscreenMaxHeight}) and (orientation: landscape) {
            text-align: center;
        }
    }
`

export const MerchantLocation = styled.div<WidgetCustomStyles>`
    border-radius: 5px;
    background-color: transparent;
    width: 100%;
    padding: 10px;
    margin: 4px 0;
    border: 1px solid #ddd;
    cursor: pointer;
`
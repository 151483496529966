import styled from "styled-components";

export const Container = styled.div`
    opacity: 0;
    display: none;
    transition: opacity 1s;
    flex-direction: column;
    background-color: transparent;
    height: 100%;
    justify-content: center;
`
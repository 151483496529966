type ChannelIconProps = {
    src: string;
    alt: string
}

export const ChannelIcon = ({src, alt}: ChannelIconProps) => {
    return (
        <img src={buildEnvAwareIconUrl(src)} alt={alt}/>
    )
}

const buildEnvAwareIconUrl = (icon: string) => {
    if (process.env.REACT_APP_FUZEY_WIDGET_BASE_URL) {
        return `${process.env.REACT_APP_FUZEY_WIDGET_BASE_URL}/${icon}`
    }
    return icon;
}
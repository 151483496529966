import styled from "styled-components";
import { WidgetSettings } from "../../contexts/WidgetSettingsContext";
import { WidgetCustomStyles } from "../../services/channels";
import { ScreenSizes } from "../../styles/global";

export const FixedContainer = styled.div<WidgetCustomStyles & {marginRight: string; marginBottom: string}>`
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: ${props => props.marginRight};
    margin-bottom: ${props => props.marginBottom};

    color: ${props => props.primaryColour || '#000000'};
    width: 384px;

    border-radius: 27px;
    overflow: auto;

    max-height: ${ScreenSizes.channels.maxHeight};

    z-index: 9999999;

    // when there isn't enought height space, reduce the channels space so that the scrollbar shows up
    @media (max-height: calc(${ScreenSizes.channels.maxHeight} + ${props => props.marginBottom})) {
        max-height: calc(100% - ${props => props.marginBottom});
    }

    // when it is mobile resolution, stretch widget to take full screen
    @media (max-width: ${ScreenSizes.fullscreenMaxWidth}), (max-height: ${ScreenSizes.fullscreenMaxHeight}) {
        margin-right: 0;
        margin-bottom: 0;

        border-radius: 0;

        width: 100%;
        height: 100%;
        max-height: 100%;
    }
`;

export const TopContainer = styled.div<WidgetCustomStyles>`
    background-color: ${props => props.primaryColour || '#0A1546'};
    color: ${props => props.secondaryColour || '#ffffff'};
`

export const BottomContainer = styled.div<WidgetCustomStyles>`
    background-color: ${props => props.secondaryColour || '#ffffff'};
    color: ${props => props.primaryColour || '#0A1546'};
    height: 100%;
`

export const CloseButton = styled.button<Partial<WidgetSettings>>`
    position: absolute;
    top: 15px;
    right: 10px;
    background-color: transparent;
    border: 0px;

    svg {
        fill: ${props => props.customStyles?.secondaryColour || '#ffffff'}
    }

    ${props => {
        if (props.buttonImageUrl) {
            return "";
        }

        return `
            @media (min-width: ${ScreenSizes.fullscreenMaxWidth}) and (min-height: ${ScreenSizes.nonfullScreenMinHeight}) {
                display: none;
            }
        `;
    }}
`

export const Content = styled.div`
    & > div {
        margin: -110px 16px 16px;

        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background-color: #ffffff;
        color: #000000;
        border-radius: 27px;
        padding: 26px 18px;
        height: 100%;

        @media (max-height:  ${ScreenSizes.fullscreenMaxHeight}) and (orientation: landscape) {
            margin-top: -45px;
        }

    }
`

export const Footer = styled.div`
    text-align: center;
    margin-top: 25px;
    margin-bottom: 29px;
    font-size: 0.875em;
    min-height: 5px;

    a {
        color: inherit;
        text-decoration: none;
        font-weight: 700;
    }
`
import { useWidgetSettings } from "../../../contexts/WidgetSettingsContext";
import { TextMessageAuthorization } from "../ConfirmationDialog/texts/TextMessageAuthorization";
import { SpinnerContainer } from "../SpinnerContainer";
import { Container, IFrame } from "./styles";


export function ContactForm() {
    const {widgetId, widgetSettings} = useWidgetSettings()

    const location = widgetSettings!.locationSettings.find(l => l.widgetId === widgetId)!;
    const config = {
        merchantId: location.merchantPublicId,
        usePlaceholders: true,
        bgColor: 'transparent',
        borderColor: 'gray',
        textColor: 'black',
        buttonColorScheme: 'twitter',
        noFooter: true,
        compactVersion: true,
        onlySMS: true,
        successTitle: widgetSettings!.successTitle,
        successSubtitle: widgetSettings!.successSubtitle,
    }

    return (
        <Container>
            { /* this spinner is meant to provide fast visual feedback that the iframe is loading for slow connections, otherwhise a blank component is be displayed for a long time */ }
            <SpinnerContainer minHeight="300px"/>
            <IFrame src={`${process.env.REACT_APP_FUZEY_CONTACT_FORM_URL}/?config=${JSON.stringify(config)}`}
                title="Contact Form"
                width="100%"
                height="100%"
                scrolling="true"
                onLoad={(iframeEvent) => {
                        const spinner = document.getElementById("iframe-spinner") as HTMLElement;
                        spinner.style.display = "none";
                        const iframe = iframeEvent.target as HTMLIFrameElement;
                        iframe.style.display = "block";
                    }
                }
            >
                Loading…
            </IFrame>
            {location.merchantCountry === "US" ? (
                <TextMessageAuthorization merchantName={location.locationName} />
            ) : null}
    </Container>
    )
}
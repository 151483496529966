import { Container } from "./styles"
import contactForm from '../../../assets/contact_form.svg';
import bookADemo from '../../../assets/book_a_demo.svg';
import { DialogType, useModalDialogContext } from "../../../contexts/ModalDialogContext";
import { ChannelIcon } from "./channelIcon";
import { useWidgetSettings } from "../../../contexts/WidgetSettingsContext";

const BOOK_A_DEMO_WIDGET_IDS = ["c65bf4fa-cad2-432e-a2ab-868cbd171432", "65de394f-35d5-4f31-9358-3b0aa5a2ea81"];

export function ContactForm({externalContactFormUrl}: {externalContactFormUrl?: string}) {
    const {open} = useModalDialogContext();
    const {widgetId} = useWidgetSettings();

    return (
        <Container 
            onClick={() => externalContactFormUrl ? window.open(`${externalContactFormUrl}`, "_blank") : open(DialogType.CONTACT_FORM)}
            style={{
                gridRow: BOOK_A_DEMO_WIDGET_IDS.includes(widgetId!) ? 1 : undefined,
            }}
        >
            {   !BOOK_A_DEMO_WIDGET_IDS.includes(widgetId!) ?
                <>
                    <div><ChannelIcon src={contactForm} alt="Contact Form"/></div>
                    <div>Contact Form</div>
                </>
                :
                <>
                    <div><ChannelIcon src={bookADemo} alt="Book a Demo"/></div>
                    <div>Book a Demo</div>
                </>                
            }
        </Container>
    )
}
import React from "react";

interface TextMessageAuthorizationProps {
    merchantName: string;
}

export function TextMessageAuthorization({ merchantName }: TextMessageAuthorizationProps) {
    return (
        <div style={{
            textAlign: "justify",
            fontSize: "0.75rem"
        }}>
            By submitting, you authorize {merchantName} to send text messages with offers & other information, possibly using automated technology, to the number you provided. <a href="https://www.getfuzey.com/company/privacy-policy" target="_blank" rel="noreferrer">Terms and rates may apply.</a> Consent is not a condition of purchase. Reply STOP to opt-out at any time.
        </div>
    )
}

import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #80D0FE;
    border-radius: 6px;
    width: 100%;
    height: 44px;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1em;
    cursor: pointer;
    
    div {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 13px;

        img {
            width: 1.75em;
        }
    }

    &:hover {
        background-color: rgba(128, 208, 254, 0.3);
    }
`

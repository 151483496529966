import { useEffect, useState } from "react";
import { useModalDialogContext } from "../../contexts/ModalDialogContext";
import { useWidgetSettings } from "../../contexts/WidgetSettingsContext";
import { Scale } from "../Animation/Scale";
import { isMobile } from "../Channels/ChannelItem/sms";
import Channels from "./../Channels";
import ContactButton from "./../ContactButton";
import { Container } from "./style";
import { useAnalytics } from "../../hooks/Analytics";
import { merchant_id } from "../..";

function App() {
  const [showChannels, setShowChannels] = useState<boolean>(false);
  const { close: closeModalDialog } = useModalDialogContext();
  const { isLoaded, loadSettings, widgetSettings, setWidgetId } = useWidgetSettings();

  const send_analytics = useAnalytics();

  const toggleChannels = () => {
    send_analytics(showChannels ? "widget_closed" : "widget_opened");

    if (!showChannels) {
      closeModalDialog();
      if (widgetSettings?.locationSettings && widgetSettings.locationSettings.length > 1) {
        setWidgetId("");
      }
    }
    setShowChannels(!showChannels);
  };

  useEffect(() => {
    if (!isLoaded) {
      loadSettings(merchant_id.split(",").map(id => id.trim()));
    } else {
      const autoOpenWidgetStorageItem =
      window.sessionStorage.getItem("autoOpenWidget");;

      const shouldAutoOpenWidget = !isMobile() &&
      // this logic is misleading - the truthiness of "false" is awkward: "false" = true and !"false" = false
      (!autoOpenWidgetStorageItem || autoOpenWidgetStorageItem === "true") &&
      widgetSettings?.preventAutoOpen !== true

      if (shouldAutoOpenWidget) {
        const delay = (widgetSettings?.delayAutoOpen || 2) * 1000
        setTimeout(() => {
          window.sessionStorage.setItem("autoOpenWidget", "false");
          setShowChannels(true);
          send_analytics("widget_auto_opened");
        }, delay);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    let autoOpenTimeout: NodeJS.Timeout;

    if (isLoaded) {
      const autoOpenWidgetStorageItem =
      window.sessionStorage.getItem("autoOpenWidget");;

      const shouldAutoOpenWidget = !isMobile() &&
      // this logic is misleading - the truthiness of "false" is awkward: "false" = true and !"false" = false
      (!autoOpenWidgetStorageItem || autoOpenWidgetStorageItem === "true") &&
      widgetSettings?.preventAutoOpen !== true

      if (shouldAutoOpenWidget) {
        const delay = (widgetSettings?.delayAutoOpen || 2) * 1000
        autoOpenTimeout = setTimeout(() => {
          window.sessionStorage.setItem("autoOpenWidget", "false");
          setShowChannels(true);
          send_analytics("widget_auto_opened");
        }, delay);
      }
    }
    return () => {
      if (autoOpenTimeout) {
        clearTimeout(autoOpenTimeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded])

  return (
    <Container className="fuzey-widget">
      {isLoaded && (
        <>
          <Scale render={showChannels} size={widgetSettings?.size || "md"}>
            <Channels
              closeChannels={() => setShowChannels(false)}
              showChannels={showChannels}
            />
          </Scale>
          <ContactButton
            toggleChannels={toggleChannels}
            isOpen={showChannels}
          />
        </>
      )}
    </Container>
  );
}

export default App;

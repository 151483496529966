import React from "react";
import ReactDOM from "react-dom/client";
import Widget from "./components/Widget";
import { ModalDialogContextProvider } from "./contexts/ModalDialogContext";
import { WidgetSettingsContextProvider } from "./contexts/WidgetSettingsContext";
import reportWebVitals from "./reportWebVitals";
import { GlobalStyle } from "./styles/global";

// download required font
var font = document.createElement("link");
font.setAttribute("rel", "stylesheet");
font.setAttribute("type", "text/css");
font.setAttribute(
  "href",
  "https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap"
);
document.head.appendChild(font);

// export necessary constants
export const merchant_id =
    document.getElementById("fuzey-widget")?.getAttribute("widget-id") || "";
export const primary_colour =
  document.getElementById("fuzey-widget")?.getAttribute("primary-colour") ||
  null;
export const secondary_colour =
  document.getElementById("fuzey-widget")?.getAttribute("secondary-colour") ||
  null;
export const success_title =
  document.getElementById("fuzey-widget")?.getAttribute("success-title") ||
  null;
export const hide_contact_form =
  document.getElementById("fuzey-widget")?.getAttribute("hide-contact-form") ||
  null;
export const success_subtitle =
  document.getElementById("fuzey-widget")?.getAttribute("success-subtitle") ||
  null;
export const button_image_url =
  document.getElementById("fuzey-widget")?.getAttribute("button-image-url") ||
  null;
export const external_contact_form =
  document
    .getElementById("fuzey-widget")
    ?.getAttribute("external-contact-form") || null;
export const logo_base64 =
  document.getElementById("fuzey-widget")?.getAttribute("logo-base64") || null;
export const extra_margin_bottom = Number(document.getElementById("fuzey-widget")?.getAttribute("extra-margin-bottom")) || 0;
export const extra_margin_right = Number(document.getElementById("fuzey-widget")?.getAttribute("extra-margin-right")) || 0;
export const widget_size =
  (document.getElementById("fuzey-widget")?.getAttribute("size") as "sm" | "md" | "lg" ) ||
  "md";

const bootWidget = () => {
  // inject widget's base div
  const div = document.createElement("div");
  div.setAttribute("id", "fuzey-widget-root");

  const body = document.body as HTMLElement;
  body.appendChild(div);

  const root = ReactDOM.createRoot(div);

  root.render(
    <React.StrictMode>
      <WidgetSettingsContextProvider>
        <ModalDialogContextProvider>
          <GlobalStyle />
          <Widget />
        </ModalDialogContextProvider>
      </WidgetSettingsContextProvider>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

try {
  bootWidget();
} catch (e) {
  if (document.readyState === 'complete') {
    setTimeout(() => {
      bootWidget()
    }, 2000);
  } else {
    window.addEventListener('load', bootWidget)
  }

}
import { useWidgetSettings } from "../contexts/WidgetSettingsContext";
import WidgetAnalyticsService, { EventTypes } from "../services/analytics";
import uuid from "react-uuid";

export function useAnalytics() {
    
    const {widgetId, widgetSettings} = useWidgetSettings();
    
    function send_analytics(eventType: EventTypes, channelId?: string, channelType?: string) {
        if (!widgetId || !widgetSettings) {
            return;
        }

        let userId = window.sessionStorage.getItem("sessionUserId");

        if (!userId) {
        userId = uuid();
        window.sessionStorage.setItem("sessionUserId", userId);
        }
    
        WidgetAnalyticsService.post(widgetSettings?.locationSettings.find(location => location.widgetId === widgetId)!.merchantPublicId, userId, eventType, channelId);
        // Publish event to data layer if it exists
        if (window.dataLayer) {
            if (channelType) {
                window.dataLayer.push({
                    event: eventType,
                    userId: userId,
                    channel: channelType,
                });
            
            } else {
                window.dataLayer.push({
                    event: eventType,
                    userId: userId,
                });
            }
        }
    }

    return send_analytics
};